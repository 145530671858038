import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import logo from "../kalakalpa.png";

const pages = [
  {
    id: 1,
    title: "Studio",
    url: "/",
  },
  {
    id: 2,
    title: "Portfolio",
    url: "/porfolio",
  },

  {
    id: 3,
    title: "Contact",
    url: "/contact-us",
  },
];
const settings = ["Profile", "Logout"];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#000000",
        contrastText: "#FFFFFF",
      },
    },
  });

  return (
    <>
      <header class="bg-white sticky top-0 z-10 bg-opacity-30 text-black backdrop-filter backdrop-blur-lg  shadow-lg hidden md:block">
        <div class="container mx-auto flex items-center h-14">
          <a href="/" class="flex items-center justify-center">
            <img className="h-12" src={logo} noWrap alt="logo"></img>
            <span class="ml-4 text-xl uppercase font-black">KALA KALPA</span>
          </a>
          <nav class="contents font-semibold text-base lg:text-lg">
            <ul class="mx-auto flex items-center">
              <li class="p-5 xl:p-8 active">
                <a href="/">
                  <span>Studio</span>
                </a>
              </li>
              <li class="p-5 xl:p-8">
                <a href="/porfolio">
                  <span>Portfolio</span>
                </a>
              </li>
              {/* <li class="p-5 xl:p-8">
                <a href="/blog">
                  <span>Blog</span>
                </a>
              </li> */}
            </ul>
          </nav>
          <a
            href="/contact-us"
            class="border border-black rounded-full font-bold px-8 py-2"
          >
            Contact Us
          </a>
        </div>
      </header>
    </>
  );
}
export default Navbar;

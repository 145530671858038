import React from "react";

const AboutUs = () => {
  return (
    <section className="dark:bg-white dark:text-gray-800 py-12">
      <div className="container flex flex-col mx-auto lg:flex-row">
        <div
          className="w-full lg:w-1/3"
          style={{
            backgroundImage: `url('https://cdn.pixabay.com/photo/2020/08/28/06/13/building-5523630_1280.jpg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="flex flex-col w-full p-6 lg:w-2/3 md:p-8 lg:p-12">
          <h2 className="text-3xl font-semibold leading-none">About Us</h2>
          <p className="mt-4 mb-8 text-2xl">
            Kala Kalpa combines two significant concepts: "Kala," which stands
            for art, creativity, and timeless beauty, and "Kalpa," which
            represents transformation, renewal, and long periods of time in
            Indian philosophy.
          </p>
          <p className="mt-4 mb-8 text-2xl">
            Together, Kala Kalpa captures the essence of enduring creativity and
            transformative design, where art and innovation come together to
            create lasting and meaningful spaces.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

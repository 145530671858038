import React from "react";

const Blog = () => {
  return (
    <>
      <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-8 lg:py-5">
        <div className="flex flex-col w-full mb-3 lg:justify-between lg:flex-row md:mb-8">
          <div className="flex items-center mb-5 md:mb-3 group lg:max-w-xl">
            <h2 className="font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl">
              <span className="inline-block mb-2">Blog</span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
            </h2>
          </div>
          <p className="w-full text-gray-700 lg:text-sm lg:max-w-md">
            Interesting group of blog to help understand the work we do better.
            Interesting group of blog to help understand the work we do better.
            Interesting group of blog to help understand the work we do better.
          </p>
        </div>
      </div>
      <div class="max-w-screen-xl mx-auto p-8">
        <div class="sm:grid lg:grid-cols-3 sm:grid-cols-2 gap-10">
          <div class="hover:bg-black hover:text-white transition duration-300 max-w-sm rounded overflow-hidden shadow-lg">
            <div class="py-2 px-8">
              <a href="#">
                <h4 class="text-lg mb-3 font-semibold">
                  How light plays an important role in the house ?
                </h4>
              </a>
              <p class="mb-2 text-sm text-gray-600">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
              <img
                src={
                  "https://cdn.pixabay.com/photo/2022/11/18/18/34/knit-cap-7600730_1280.jpg"
                }
                class="w-100"
              />
              <hr class="mt-4" />
              <span class="text-xs">ARTICLE</span>
              &nbsp;<span class="text-xs text-gray-500">PROCESS</span>
            </div>
          </div>
          <div class="hover:bg-black hover:text-white transition duration-300 max-w-sm rounded overflow-hidden shadow-lg">
            <div class="py-2 px-8">
              <a href="#">
                <h4 class="text-lg mb-3 font-semibold">
                  Saving energy is important, dont you think ?
                </h4>
              </a>
              <p class="mb-2 text-sm text-gray-600">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
              <img
                src="https://cdn.pixabay.com/photo/2013/07/12/18/55/energy-efficiency-154006_1280.png"
                class="w-100"
              />
              <hr class="mt-4" />
              <span class="text-xs">ARTICLE</span>
              &nbsp;<span class="text-xs text-gray-500">PROCESS</span>
            </div>
          </div>
          <div class="hover:bg-black hover:text-white transition duration-300 max-w-sm rounded overflow-hidden shadow-lg">
            <div class="py-2 px-8">
              <a href="#">
                <h4 class="text-lg mb-3 font-semibold">
                  Changing the way we live in the concert jungle
                </h4>
              </a>
              <p class="mb-2 text-sm text-gray-600">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
              <img
                src="https://cdn.pixabay.com/photo/2016/11/18/19/10/beach-1836467_1280.jpg"
                class="w-100"
              />
              <hr class="mt-4" />
              <span class="text-xs">ARTICLE</span>
              &nbsp;<span class="text-xs text-gray-500">PROCESS</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;

import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const ParallexEffect = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
  const textY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  return (
    <div ref={ref} className="w-full h-screen overflow-hidden relative grid">
      <motion.h1
        style={{
          y: textY,
          paddingLeft: "7em",
          paddingRight: "7em",
          marginLeft: "0.33em",
          marginTop: "2.3em",
        }}
        className="font-bold text-white text-3xl md:text-5xl  z-10"
      >
        Get your dream home where nature and luxury meet
        <span className="text-red-900">.</span>
      </motion.h1>

      <motion.div
        className="absolute inset-0 z-0 "
        style={{
          backgroundImage: `url(${require("../heroImage.png")})`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          y: backgroundY,
        }}
      />
      <div
        className="absolute inset-0 z-0 "
        style={{
          backgroundImage: `url(${require("../heroCut.png")})`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      />
    </div>
  );
};

export default ParallexEffect;

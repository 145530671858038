import { DragCards } from "./DragCards";
import bacanyView from "../images/mainImage1.jpg";
import sittingview from "../images/mainview.jpg";
import entrance from "../images/entrance.jpg";
import lehbuilding from "../images/view4.jpg";
import traditional from "../images/traditional.jpg";
import finish from "../images/finish.jpg";
import viewWindow from "../images/window2.jpg";
import finishart from "../images/art.jpg";
import differenteglu from "../images/different.jpg";
import lehview from "../images/view3.jpg";
import rocks from "../images/rocks.jpg";
import bags from "../images/bags.jpg";
import bags1 from "../images/bags2.jpg";
import bagsView from "../images/bagsview.jpg";
import skyBagsView from "../images/view1.jpg";
import darkbagsView from "../images/view2.jpg";
import sand from "../images/sand.jpg";

export const Portfolio = () => {
  return (
    <>
      <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col w-full mb-3 lg:justify-between lg:flex-row md:mb-8">
          <div className="flex items-center mb-5 md:mb-3 group lg:max-w-xl">
            <h2 className="font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl">
              <span className="inline-block mb-2">Our Work</span>
              <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
            </h2>
          </div>
          <p className="w-full text-gray-700 lg:text-sm lg:max-w-md">
            Some of aspects and technique for a our work and art.Some of aspects
            and technique for a our work and art.Some of aspects and technique
            for a our work and art.Some of aspects and technique for a our work
            and art.Some of aspects and technique for a our work.
          </p>
        </div>
      </div>

      <div class="max-w-screen-2xl px-1 py-1 mx-auto relative bg-white">
        <div class="flex flex-col md:flex-row gap-1">
          <div class="flex flex-1 flex-col">
            <div class="flex flex-1 flex-col">
              <img class="object-cover h-full" src={sittingview} alt="" />
            </div>
          </div>
          <div class="flex flex-1">
            <div class="grid grid-cols-2 gap-1">
              <div>
                <img class="object-cover h-full" src={bacanyView} alt="" />
              </div>
              <div>
                <img class="object-cover h-full" src={finishart} alt="" />
              </div>
              <div>
                <img class="object-cover h-full" src={rocks} alt="" />
              </div>
              <div>
                <img class="object-cover h-full" src={entrance} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-screen-2xl px-1 mx-auto relative bg-white">
        <div class="flex flex-col md:flex-row gap-1">
          <div class="flex flex-1 flex-col">
            <div class="flex flex-1 flex-col">
              <img class="object-cover h-full" src={lehbuilding} alt="" />
            </div>
          </div>
          <div class="flex flex-1">
            <div class="grid grid-cols-2 gap-1">
              <div>
                <img class="object-cover h-full" src={finish} alt="" />
              </div>
              <div>
                <img class="object-cover h-full" src={viewWindow} alt="" />
              </div>
              <div>
                <img class="object-cover h-full" src={lehview} alt="" />
              </div>
              <div>
                <img class="object-cover h-full" src={differenteglu} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="max-w-screen-2xl mx-auto px-1 pb-3 relative bg-gray-50">
        <div class="flex flex-col md:flex-row gap-1">
          <div class="flex flex-1 flex-col gap-1">
            <div class="flex flex-1 flex-col">
              <img class="object-cover h-full" src={bagsView} />
            </div>
            <div class="hidden md:flex flex-1 flex-row gap-1">
              <div class="flex flex-1 flex-col">
                <img class="object-cover h-full" src={sand} />
              </div>
              <div class="hidden md:flex flex-1 flex-col">
                <img class="object-cover h-full" src={skyBagsView} />
              </div>
            </div>
          </div>
          <div class="flex flex-1 flex-col gap-1">
            <div class="hidden md:flex flex-1 flex-row gap-1">
              <div class="flex flex-1 flex-col">
                <img class="object-cover h-full" src={bags} />
              </div>
              <div class="hidden md:flex flex-1 flex-col">
                <img class="object-cover h-full" src={bags1} />
              </div>
            </div>
            <div class="flex flex-1 flex-col">
              <img class="object-cover h-full" src={darkbagsView} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Container, Box, Typography, Divider } from "@mui/material";

const ContactPage = () => {
  const [fullname, setFullname] = useState("");
  const [reason, setReason] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const serviceId = "service_jy3v4dp";
  const templateId = "template_yf6hnsm";
  const publicKey = "W7tZI3BR0pzpOrmej";
  const form = useRef();
  console.log(form);
  const sendEmail = (e) => {
    e.preventDefault();

    const templateparams = {
      from_name: fullname,
      to_name: "Akhil",
      reason: reason,
      from_email: email,
      phone: phone,
      message: message,
      reply_to: email,
    };

    console.log(templateparams);
    emailjs.send(serviceId, templateId, templateparams, publicKey).then(
      () => {
        alert("SUCCESS!");
      },
      (error) => {
        alert("FAILED...", error.text);
        alert(error.text);
      }
    );
  };

  return (
    <>
      <div class="my-4 flex justify-center items-center w-screen h-screen bg-white">
        <div class="container mx-auto my-4 px-4 lg:px-20">
          <div class="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
            <div class="flex">
              <h2 class="font-bold uppercase text-4xl">
                Contact <br /> Us
              </h2>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                <input
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  name="Fullname"
                  placeholder="Name*"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  required
                />
                <select
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  name="reason"
                  defaultValue={reason}
                  onChange={(e) => setReason(e.target.value)}
                >
                  <option value={""}>--Select--</option>
                  <option value={"nResidential_Home"}>Residential Home</option>
                  <option value={"Commerial"}>Commerial</option>
                  <option value={"Interiors"}>Interiors</option>
                </select>
                <input
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="email"
                  name="email"
                  placeholder="Email*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="number"
                  name="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Phone*"
                />
              </div>
              <div class="my-4">
                <textarea
                  name="message"
                  placeholder="Message*"
                  onChange={(e) => setMessage(e.target.value)}
                  class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                ></textarea>
              </div>
              <div class="my-2 w-1/2 lg:w-1/4">
                <button
                  type="submit"
                  class="uppercase text-sm font-bold tracking-wide bg-black text-gray-100 p-3 rounded-lg w-full 
                      focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>

          <div class="w-full lg:-mt-96 lg:w-2/6 px-8 py-12 ml-auto bg-black rounded-2xl">
            <div class="flex flex-col text-white">
              <h1 class="font-bold uppercase text-3xl my-4">
                Drop in our office
              </h1>
              <p class="text-gray-400">This is where the magic happens</p>

              <div class="flex my-4 w-2/3 lg:w-1/2">
                <div class="flex flex-col">
                  <i class="fas fa-map-marker-alt pt-2 pr-2" />
                </div>
                <div class="flex flex-col">
                  <h2 class="text-2xl">KALA KALPA</h2>
                  <p class="text-gray-400">
                    c, 15/1, 2nd Cres Rd, Sainikpuri, Hyderabad, Secunderabad,
                    Telangana 500076
                  </p>
                </div>
              </div>

              <div class="flex my-4 w-2/3 lg:w-1/2">
                <div class="flex flex-col">
                  <i class="fas fa-phone-alt pt-2 pr-2" />
                </div>
                <div class="flex flex-col">
                  <h2 class="text-2xl">Call Us</h2>
                  <p class="text-gray-400">+91 8074539321</p>
                  <p class="text-gray-400">info@kalakalpa.com</p>
                </div>
              </div>

              <div class="flex my-4 w-2/3 lg:w-1/2">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  class="rounded-full bg-white h-8 w-8 inline-block mx-1 text-center pt-1"
                >
                  <i class="fab fa-facebook-f text-blue-900" />
                </a>
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  class="rounded-full bg-white h-8 w-8 inline-block mx-1 text-center pt-1"
                >
                  <i class="fab fa-linkedin-in text-blue-900" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;

import React from "react";

const Contact = () => {
  return (
    <div class="bg-white ">
      <div class="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2 class="text-3xl font-extrabold text-black sm:text-4xl">
          <span class="block">Is there anything else before we start ?</span>
          <span class="block text-teal-900 ">Reach out to us</span>
        </h2>
        <p class="text-xl mt-4 max-w-md mx-auto text-gray-400">
          we will be very happy to help you find all the answer you are looking
          for.
        </p>
        <div class="lg:mt-0 lg:flex-shrink-0">
          <div class="mt-12 inline-flex rounded-md shadow">
            <a
              href="/contact-us"
              type="button"
              class="py-4 px-6  text-teal-900 hover:text-teal-950  focus:ring-teal-500 focus:ring-offset-indigo-200 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Footer from "./Footer";
import { HeroSection } from "./HeroSection";
import ContactPage from "./ContactPage";
import { Portfolio } from "./Porfolio";
import Blog from "./Blog";
const Router = () => {
  const Layout = () => {
    return (
      <>
        <Navbar />
        <Outlet />
        <Footer />
      </>
    );
  };

  const BrowserRoutes = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <HeroSection />,
        },
        {
          path: "/porfolio",
          element: <Portfolio />,
        },
        {
          path: "/blog",
          element: <Blog />,
        },
        {
          path: "/contact-us",
          element: <ContactPage />,
        },
      ],
    },
  ]);
  return <RouterProvider router={BrowserRoutes} />;
};

export default Router;
